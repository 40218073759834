import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { Storage } from '@capacitor/storage';

var userLogin = {
  "codcli": "",
  "pass": ""
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  constructor(private http: HttpClient) {
    this.loadToken();
  }

  async loadToken() {
    const { value } = await Storage.get({ key: 'user' });
    if (value) {
    this.http.post('https://diloa.appcloud.es/logger-7swooe7n_new.php', JSON.stringify({ "details": `user ${JSON.parse(value)[0].CODCLI.trim()}: usuario ya logueado accediendo`})).subscribe(data => { })
      this.token = value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { email, password }): Observable<any> {

    userLogin = {
      "codcli": credentials.email,
      "pass": credentials.password,
    }

    let user = {
      "codcli": credentials.email,
      "pass": credentials.password,
    }
    let headers = new HttpHeaders({
      'Content-type': 'application/json',
    });

    return this.http.post(`https://diloa.duckdns.org:1515/api/login`, user, { headers: headers }).pipe(
      map((data: any) => data),
      switchMap(async token => {
        this.http.post('https://diloa.appcloud.es/logger-7swooe7n_new.php', JSON.stringify({ "details": `user ${userLogin.codcli}: login` })).subscribe(data => { })
        return await Storage.set({ key: 'user', value: JSON.stringify(token) });
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    )
  }

  async logout(): Promise<void> {
    const { value } = await Storage.get({ key: 'user' })
    this.http.post('https://diloa.appcloud.es/logger-7swooe7n_new.php', JSON.stringify({ "details": `user ${JSON.parse(value)[0].CODCLI.trim()}: logout `})).subscribe(data => { })
    this.isAuthenticated.next(false);
    return await Storage.remove({ key: 'user' });
  }
}
import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Parte', url: '/folder/Inbox', icon: 'clipboard' },
/*     { title: 'Comensales', url: '/folder/Outbox', icon: 'fast-food' }, */
  ];
  constructor(private authService: AuthenticationService, private router: Router) {}

async logout() {
    await this.authService.logout();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

}
